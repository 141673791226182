.template-value {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.template-body {
    width: 90%;
    margin: 0px 10px 0px 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.template-title {
    font-size: 1.2em;
}

.template-value-img {
    border: none;
    background: none;
}

.template-value-img:disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.6;
}

.template-value .prontocobro-tag{
    background-color: #00bcd4;
    font-size: 13px;
}

.business-integration-button {
    background: transparent;
    color: var(--green-600);
    border-color: var(--green-600);
}

.copy-template-uuid {
    --background-hover: green;
    --primary-focus: red;
    color: #FD971F;
    cursor: pointer;
}

.copy-template-group {
    --background-hover: green;
    --primary-focus: red;
    color: #be12d8;
    cursor: pointer;
}

.copy-template-uuid:hover {
    color: #1E90FF;
}

.copy-template-group:hover {
    color: #1E90FF;
}

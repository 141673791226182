.message {
  display: flex;
  flex-direction: column;
}

.note-reminder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-note {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bubble-container {
  margin-top: 20px;
}

.bubble-container-animation-in {
  animation-name: shake;
  animation-duration: 4s;
  animation-timing-function: cubic-bezier(.24,.91,.01,.99);
}

.bubble-container-animation-out {
  animation-name: shake;
  animation-duration: 4s;
  animation-timing-function: cubic-bezier(.24,.91,.01,.99);
}

.message .timestamp {
  display: flex;
  justify-content: center;
  color: #777;
  font-weight: 600;
  font-size: 12px;
  margin: 10px 0px;
  text-transform: uppercase;
}

.message .bubble-container {
  font-size: 14px;
  display: flex;
  font-weight: 400;
}

.note-reminder .bubble-container {
  font-size: 14px;
}

@media (max-width: 600px) {
  .message .timestamp {
    font-size: 11px;
  }

  .message .bubble-container {
    font-size: 13px;
  }

  .note-reminder .bubble-container {
    font-size: 13px;
  }
}

.message.mine .bubble-container {
  justify-content: flex-end;
}

.message.start .bubble-container .bubble {
  border-top-left-radius: 20px;
}

.message.end .bubble-container .bubble {
  border-bottom-left-radius: 20px;
}

.message.mine.start .bubble-container .bubble {
  margin-top: 10px;
  border-top-right-radius: 20px;
}

.message.mine.end .bubble-container .bubble {
  border-bottom-right-radius: 20px;
  margin-bottom: 10px;
}

.message.other .bubble-container .bubble {
  position: relative;
  margin-left: 20px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #ffffff;
  text-align: left;
  border: 1px solid #ffffff;
  border-radius: 10px;
  max-width: 75%;
  margin-top: 8px;
  margin-bottom: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  /* Pseudo-elements */
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 15px solid #ffffff;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    top: 0;
    left: -15px;
  }
  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 17px solid #ffffff;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    top: -1px;
    left: -17px;
  }
}

.message.other .bubble-container .bubble audio{
  background-color: #ffffff;
  border-radius: 10px;
}

.message.other .bubble-container .bubble audio:hover {
  background-color: #ffffff;
}

.message.other .bubble-container .bubble audio::-webkit-media-controls-panel {
  background-color: #ffffff;
}
.message.other.detach_bot .bubble-container .bubble,
.message.other.detach .bubble-container .bubble {
  background-color: #FFB347;
  border: 1px solid #FFB347;
  &::after {
    border-top: 15px solid #FFB347;
  }
  &::before {
    border-top: 17px solid #FFB347;
  }
}

.note-reminder .bubble-container .bubble {
  margin: 1px 0px;
  background: #e6e6e6;
  padding: 10px 15px;
  border-radius: 10px;
  max-width: 100%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}

.message-note .bubble-container .bubble {
  margin: 1px 0px;
  background: #e6e6e6;
  padding: 10px 15px;
  border-radius: 10px;
  max-width: 100%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}

.message.mine .bubble-container .bubble {
  position: relative;
  margin-right: 20px;
  margin-bottom: 10px;
  padding: 10px;
  max-width: 75%;
  background-color: #95DBCCE5;
  text-align: left;
  border: 1px solid #95DBCCE5;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  /* Pseudo-elements */
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    top: 0;
    right: -15px;
  }
  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 17px solid #95DBCCE5;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    top: -1px;
    right: -17px;
  }
}

.message.mine.detach .bubble-container .bubble audio{
  background-color: #FFB347;
}

.message.mine.detach .bubble-container .bubble audio:hover {
  background-color: #FFB347;
}

.message.mine.detach .bubble-container .bubble audio::-webkit-media-controls-panel {
  background-color: #FFB347;
}

.message.mine .bubble-container .bubble audio{
  background-color: #95DBCCE5;
  border-radius: 10px;
}

.message.mine .bubble-container .bubble audio:hover {
  background-color: #95DBCCE5;
}

.message.mine .bubble-container .bubble audio::-webkit-media-controls-panel {
  background-color: #95DBCCE5;
}

.message.mine.failed .bubble-container .bubble {
  position: relative;
  margin-right: 20px;
  margin-bottom: 10px;
  padding: 10px;
  max-width: 75%;
  background-color: #e0705c;
  text-align: left;
  font: 400 .9em 'Open Sans', sans-serif;
  border: 1px solid #e0705c;
  border-radius: 10px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  /* Pseudo-elements */
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 15px solid #e0705c;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    top: 0;
    right: -15px;
  }
  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 17px solid #e0705c;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    top: -1px;
    right: -17px;
  }
}

.message.mine.failed .bubble-container .bubble .message-file-bubble-in {
  background-color: #e28170 !important;
}

.message.mine.notification .bubble-container .bubble .message-preview-context-in,
.message.mine.chatbot .bubble-container .bubble .message-preview-context-in,
.message.mine.autoreply .bubble-container .bubble .message-preview-context-in,
.message.mine.welcome_message .bubble-container .bubble .message-preview-context-in,
.message.mine.note_reminder .bubble-container .bubble .message-preview-context-in,
.message.mine.scheduled_message_sent .bubble-container .bubble .message-preview-context-in {
  background-color: #a2cbfa;
}

.message-preview-context-in

.message.mine.detach_bot .bubble-container .bubble,
.message.mine.detach .bubble-container .bubble {
  background-color: #FFB347;
  border: 1px solid #FFB347;
  &::after {
    border-top: 15px solid #FFB347;
  }
  &::before {
    border-top: 17px solid #FFB347;
  }
}

.message.mine.notification .bubble-container .bubble,
.message.mine.chatbot .bubble-container .bubble,
.message.mine.autoreply .bubble-container .bubble,
.message.mine.welcome_message .bubble-container .bubble,
.message.mine.note_reminder .bubble-container .bubble,
.message.mine.scheduled_message_sent .bubble-container .bubble {
  position: relative;
  margin-right: 20px;
  margin-bottom: 10px;
  padding: 10px;
  max-width: 75%;
  background-color: #91c3fc;
  text-align: left;
  font: 400 .9em 'Open Sans', sans-serif;
  border: 1px solid #91c3fc;
  border-radius: 10px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  /* Pseudo-elements */
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 15px solid #91c3fc;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    top: 0;
    right: -15px;
  }
  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 17px solid #91c3fc;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    top: -1px;
    right: -17px;
  }
}

.retry .bubble-container .bubble {
  position: relative;
  margin-right: 20px;
  margin-bottom: 10px;
  padding: 10px;
  max-width: 75%;
  background-color: #ffee007e;
  text-align: left;
  font: 400 .9em 'Open Sans', sans-serif;
  border: 1px solid #ffee007e;
  border-radius: 10px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  /* Pseudo-elements */
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 15px solid #ffee007e;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    top: 0;
    right: -15px;
  }
  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 17px solid #ffee007e;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    top: -1px;
    right: -17px;
  }
}

.fontawesome-margin {
  margin-bottom: -0.5em;
}

.note-reminder-fontawesome {
  display: contents;
}

.center {
  text-align: center;
}

.forward-message {
  margin-left: 1em;
  flex: auto;
  margin-top: 1.5em;
  max-width: 30px;
}

.forward-message > .p-checkbox-disabled{
  cursor: not-allowed;
}

.message-selection-highlight {
  background-color: #05bb9a47;
}
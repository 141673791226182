.reassign-button {
    position: absolute;
    width: 90%;
    bottom: 10px;
}

@media screen and (max-width: 600px) {
    .reassign-button {
        width: 85%;
    }
}
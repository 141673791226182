.conversation-search {
  padding: 15px 5px 15px 5px;
  flex-direction: column;
  width: 372px;
  margin-left: 15px;
  margin-right: 16px;
}

.internal-conversation-search {
  padding: 15px 5px 15px 5px;
  flex-direction: column;
  width: 400px;
  margin-left: 15px;
  margin-right: 16px;
}

.conversation-search-input {
  background: #fff;
  border: solid 3px #dcdddc;
  border-radius: 10px;
  font-size: 14px;
  width: 103%;
}

.conversation-search-input::placeholder {
  opacity: 0.5;
}

.p-input-icon-left.conversation-search > .pi-search,
.p-input-icon-left.conversation-search > .pi-spinner{
  position: absolute;
  left: 20px;
}

.conversation-search-input.loading {    
  background-color: #f4f4f8;
  background-image: url("../../../assets/images/loading.gif");
  background-size: 25px 25px;
  background-position:right center;
  background-repeat: no-repeat;
}

.conversation-search-input:focus::placeholder {
  color: transparent;
}

.internal-conversation-search .clean,
.conversation-search .clean {
  cursor: pointer;
  right: 5px;
}


.transferred-tag {
    width: 14rem;
    color: rgb(240, 240, 240);
    background-color: #3975ad;
    font-size: 12px;
    border-radius: 0px 0px 10px 10px;
}

.transferred-tag-icon {
    padding-right: 0.6rem;
    font-size: 0.9rem;
}
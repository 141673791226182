.carousel-card {
    display: flex;
    flex-direction: column;
    background-color: #2a9fd600;
    border-radius: 10px;
    box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    white-space: normal;
}

.p-carousel{
    display: flex;
    flex-direction: column;
    width: 32rem;
    padding: 0;
    padding-top: 0;
}

.p-carousel-items-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.own-carousel-badge {
    background-color: rgba(116, 172, 154, 0.788);
    color: rgb(240, 240, 240);
    border-radius: 1rem;
    width: 4.5rem;
    padding: 0;
    padding-top: 0;
    margin-left: 8%;
}

.other-carousel-badge {
    background-color: rgba(116, 116, 116, 0.32);
    color: rgb(31, 31, 31);
    font-weight: normal;
    border-radius: 1rem;
    width: 4.5rem;
    padding: 0;
    padding-top: 0;
    margin-bottom: 0.6rem;
    margin-left: 8%;
}

.own-contact-card {
    background-color: #8ac9bc69;
    width: 97%;
    border-radius: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    margin: 0.5rem;
    word-wrap: break-word;
}

.other-contact-card {
    background-color: #7d7d7d15;
    width: 97%;
    border-radius: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    margin: 0.5rem;
    word-wrap: break-word;
}

.vcard-info {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
}

.vcard-info-phone {
    margin-top: 0.3rem;
    word-wrap: break-word;
}

.vcard-info-mail {
    margin-top: 0.3rem;
    color: rgba(0, 0, 0, 0.61);
}
.vcard-info-name {
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.61);
    font-style: normal;
  }

@media (max-width: 600px) {
    .carousel-card {
        display: flex;
        flex-direction: column;
        background-color: #2a9fd600;
        border-radius: 10px;
        box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.1);
        width: 16rem;
        white-space: normal;
    }
    .vcard-info-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 125px;
      }
    .own-contact-card {
        background-color: #8ac9bc69;
        width: 97%;
        border-radius: 1rem;
        padding-bottom: 1rem;
        padding-left: 1rem;
        margin: 0.5rem;
        word-wrap: break-word;
    }
    .p-carousel{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .p-carousel-items-content {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    .carousel-badge {
        background-color: rgba(116, 172, 154, 0.788);
        color: rgb(240, 240, 240);
        border-radius: 1rem;
        width: 4.5rem;
        padding: 0;
        padding-top: 0;
        margin-left: 18%;
        margin-bottom: 0.3rem;
    }
  }

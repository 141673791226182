.datascroller-notes > .p-datascroller > .p-datascroller-header {
    font-size: x-large;
    font-weight: bolder;
}

.reminder-not-expired {
    display: flex;
}

.reminder- {
    display: flex;
}

.reminder-expired {
    display: flex;
    background-color: #ffddc5;
    border-radius: 1em;
    padding-top: 0.5em
}

.note-button {
    padding: 8px 20px 10px 10px;
}

.note-desc {
    padding-left: 20px;
}

.note-body {
    position: absolute;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
}

.note-radio-button-disabled {
    opacity: 0.3;
}

.note-button-cancel {
    cursor: not-allowed;
    opacity: 0.6;
}

.note-button-cancel:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.custom-bar-tags-filtered {
    margin-top: 1em;
    border-radius: 0 !important;
    border: none !important;
    border-top: 1px solid #bdbdbd !important;
}

.custom-bar-tags-filtered .p-scrollpanel-wrapper {
    margin-top: 1em;
}

.p-scrollpanel.custom-bar-tags-filtered .p-scrollpanel-bar {
    opacity: 1;
    transition: background-color .2s;
}

.p-scrollpanel-bar.p-scrollpanel-bar-y {
    margin-top: 1em;
}

.text-small {
    overflow-wrap: break-word;
}
.messenger {
  display: grid;
  width: 100%;
  height: 99%;
  grid-template-columns: 450px auto;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}

.messenger-logo-container h4 {
  color: #5a5a5a;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
}

.messenger-logo-container span{
  color: #5a5a5a;
  font-size: 13px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
}

.content .p-virtualscroller-content{
  position: relative;
  overflow-x: hidden;
}

@media (max-height: 700px) {
  .content .p-virtualscroller {
    height: 61vh !important;
  }
  .messenger-conversation-list-container > .content{
    height: 61vh;
  }
  .messenger-message-list-container > .content{
    height: 87.5vh;
  }
  .message-list-container {
    min-height: 60vh;
  }
  .messenger {
    height: 87.5vh;
  }
  .content-imagen {
    height: 85%;
    width: 90%;
  }
}

@media (max-height: 750px) {
  .content .p-virtualscroller {
    height: 60vh !important;
  }
  .messenger-conversation-list-container > .content{
    height: 60vh;
  }
  .messenger-message-list-container > .content{
    height: 87.5vh;
  }
  .message-list-container {
    min-height: 60vh;
  }
  .messenger {
    height: 87.5vh;
  }
  .content-imagen {
    height: 85%;
    width: 92%;
  }
}

@media (min-height: 710px) {
  .content .p-virtualscroller {
    height: 66vh !important;
  }
  .messenger-conversation-list-container > .content{
    height: 66vh;
  }
  .messenger-message-list-container > .content{
    height: 89vh;
  }
  .message-list-container {
    min-height: 66vh;
  }
  @media (min-height: 750px) {
    .content .p-virtualscroller {
      height: 72vh !important;
    }
    .messenger-conversation-list-container > .content{
      height: 72vh;
    }
    .messenger-message-list-container > .content{
      height: 91vh;
    }
    .messenger {
      height: 90vh;
    }
    .message-list-container {
      min-height: 72vh;
    }
    .content-imagen {
      height: 90%;
      width: 90%;
    }
    @media (min-height: 800px) {
      .content .p-virtualscroller {
        height: 71vh !important;
      }
      .messenger-conversation-list-container > .content{
        height: 71vh;
      }
      .messenger-message-list-container > .content{
        height: 91vh;
      }
      .messenger {
        height: 91vh;
      }
      .message-list-container {
        min-height: 72vh;
      }
    }
    @media (min-height: 932px) {
      .content .p-virtualscroller {
        height: 73vh !important;
      }
      .messenger-conversation-list-container > .content{
        height: 73vh;
      }
      .messenger-message-list-container > .content{
        height: 91vh;
      }
      .messenger {
        height: 91vh;
      }
      .message-list-container {
        min-height: 73vh;
      }
    }
    @media (min-height: 950px) {
      .content .p-virtualscroller {
        height: 72vh !important;
      }
      .messenger-conversation-list-container > .content{
        height: 72vh;
      }
    }
    @media (min-height: 975px) {
      .content .p-virtualscroller {
        height: 75vh !important;
      }
      .messenger-conversation-list-container > .content{
        height: 75vh;
      }
    }
    @media (min-height: 1125px) {
      .content .p-virtualscroller {
        height: 76vh !important;
      }
      .messenger-conversation-list-container > .content{
        height: 76vh;
      }
      .messenger {
        height: 91vh;
      }
      .message-list-container {
        min-height: 79vh;
      }
    }
  }
}

@media (max-width: 600px) {
  .layout-content-tb-hidden .messenger{
    height: 92vh;
    height: 99dvh;
  }
  .layout-content-tb-hidden .content {
    height: 99vh;
    height: 99dvh;
  }
  .messenger {
    grid-template-columns: none;
  }
  .messenger {
    width: 100% !important;
    height: 99dvh;
  }
  .messenger .messenger-message-list-container{
    width: 100% !important;
  }
  .content-imagen {
    height: 95%;
    width: 100%;
  }
}

.container {
  padding: 10px;
}

.scrollable {
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.scrollable.sidebar{
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
}

@media (max-width: 600px) {
   .l-topbar-messenger-hidden .scrollable {
    height: 92.5%;
  }
}

::-webkit-scrollbar {
  width: 6px!important;
  height: 6px!important;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(153, 153, 156);
}

::-webkit-scrollbar-track {
  background: #fff;
}

.sidebar {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 2;
}

.content {
  background-color: transparent !important;
}

.messenger-message-list-container > .content{
  display: grid;
}

.messenger-conversation-list-container > .content{
  display: grid;
}

.messenger-conversation-list-container {
  position: relative;
  display: grid;
}

.content-imagen {
  position: absolute; /* Establece posición absoluta */
  right: 14px;
  background: url("../../../assets/images/whatsapp-background.png"), #e7e7e7;
  grid-row-start: 1;
  grid-row-end: span 2;
  z-index: -1;
}
.footer {
  grid-column-start: 2;
  background: white;
}

.image-logo {
  position: relative;
  text-align: center;
  top: 25%;
  justify-content: center;
  align-items: center;
}

.image-logo img {
  width: 35%;
  height: 35%;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
}

.image-logo h4,
.image-logo span {
  margin-top: 10px;
}

.image-logo h4 {
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
}

.image-logo span {
  font-size: 13px;
  font-style: italic;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
}
.accordion-bot-response-form > .p-accordion-tab > .p-accordion-header > .p-accordion-header-link {
    background: #858A7938 !important;
    margin-bottom: 1.5em;
}

.p-splitbutton.p-button-pip {
    padding-top: 6px
}

.p-splitbutton.p-button-pip-disable {
    padding-top: 6px
}

.p-splitbutton-button {
    color:red
}

.bot-response-textarea {
    resize: none;
}

.bot-response-question {
    resize: none;
    width: 50%;
}

.p-inputgroup .p-inputtext, .p-fluid .p-inputgroup .p-inputtext, .p-inputgroup .p-inputwrapper, .p-fluid .p-inputgroup .p-input {
    width: 100%;
}

.space-left{
    padding-left: 5px;
}

.space-right{
    padding-right: 8px;
}

.success_message_items {
    width: 3rem;
}

.success_message_items > .p-button.p-component.p-splitbutton-defaultbutton {
    display: none;
}

.bot-response-multimedia-group {
    position: relative;
}

.bot-response-multimedia {
    position: absolute;
    display: flex;
    right: 0;
    z-index: 2;
}

.bot-response-multimedia > i {
    background-color: white;
    color: #31D1AEE5;
    position: relative;
    right: 5rem;
    top: 1rem;
    padding: 1px;
}

.bot-response-multimedia.p-inputgroup-addon:last-child {
    border: none;
}

.icons {
    position: absolute;
    display: flex;
    right: 0;
    z-index: 2;
}

.icons > i {
    background-color: transparent;
    color: #31D1AEE5;
    position: relative;
    right: 5rem;
    top: 1rem;
    padding: 1px;
}

.bot-response-icons {
    background-color: transparent;
    color: #31D1AEE5;
    position: relative;
    right: 5rem;
    top: 1rem;
    padding: 1px;
}

.sidebar-sheet {
    max-width: 0;
}

.sidebar-sheet > i {
    cursor: pointer;
    position: relative;
    right: 2rem;
    top: 1rem;
}

.popup-edit {
    max-width: 0;
}

.popup-edit > i {
    background-color: transparent;
    cursor: pointer;
    position: relative;
    right: 2rem;
    top: 1rem;
    z-index: 2;
}

.popup-option-edit {
    max-width: 0;
}

.popup-option-edit > i {
    cursor: pointer;
    position: relative;
    right: 2.5rem;
    top: 1rem;
    z-index: 2;
}

.popup-right-edit {
    max-width: 0;
}

.popup-right-edit > i {
    cursor: pointer;
    position: relative;
    right: 4rem;
    top: 1rem;
}

.hashtag {
    max-width: 0;
}

.hashtag > i {
    position: relative;
    right: 4rem;
    top: 1rem;
}

.hashtag-right {
    max-width: 0;
}

.hashtag-right > i {
    position: relative;
    right: 6rem;
    top: 1rem;
}

.webhook-right {
    max-width: 0;
}

.webhook-right > i {
    position: relative;
    right: 6rem;
}

.webhook {
    max-width: 0;
}

.webhook > i {
    position: relative;
    right: 4rem;
    top: 1rem;
}

.sheet-right {
    max-width: 0;
}

.sheet-right > i {
    position: relative;
    right: 6rem;
}

.sheet {
    max-width: 0;
}

.sheet > i {
    position: relative;
    right: 4rem;
}

.accordion-bot-response-button {
    position: absolute;
    right: 60px;
    cursor: pointer;
}

.accordion-bot-response-button > i {
    position: absolute;
    cursor: pointer;
    top: 18px;
}